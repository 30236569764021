<style lang="less" scoped>
  .report-nucleic-counts {
    padding: 10px;
  }
  .content {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nums {
    display: flex;
    margin-top: 20px;
    .num {
      background-color: #F8F8F8;
      border-radius: 5px;
      width: 200px;
      padding: 30px 0;
      .name {
        color: #657180;
        font-size: 15px;
        margin-top: 10px;
        text-align: center;
      }
      .value {
        color: #000;
        font-weight: 800;
        text-align: center;
        font-size: 20px;
      }
      & + .num {
        margin-left: 10px;
      }
    }
  }
  table {
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    border-collapse: collapse;
    flex: 1;
    height: 0;
    overflow: auto;
  }
  th, td {
    padding: 8px 20px;
    text-align: center;
    border: 1px solid #000;
  }
</style>

<template>
  <div class="report-nucleic-counts">
    <div class="content">
      <div class="title">
        <fm-title style="height: unset;min-height:unset;" title-text="核算报告 - 汇总统计"></fm-title>
        <fm-form inline>
          <fm-form-item style="margin-bottom:0;" label="采样单位" v-loadingx="loading.cydd">
            <fm-select v-model="search.cydd" filterable clearable style="width: 150px;">
              <fm-option v-for="item in cyddList" :key="item" :label="item" :value="item"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item style="margin-bottom:0;" label="采样日期">
            <DatePicker show-week-numbers :clearable="false" type="daterange" v-model="search.cyrq" :options="dateOptions" placeholder="请选择采样日期" style="width: 200px"></DatePicker>
          </fm-form-item>
          <fm-form-item style="margin-bottom:0;">
            <fm-btn v-loadingx="loading.load" long @click="loadData()">查询</fm-btn>
          </fm-form-item>
        </fm-form>
      </div>
      <div class="nums">
        <div class="num">
          <div class="value" data-precision="0" v-num-to="datas.single"></div>
          <div class="name">单采管数</div>
        </div>
        <div class="num">
          <div class="value" data-precision="0" v-num-to="datas.mix"></div>
          <div class="name">混采管数</div>
        </div>
        <div class="num">
          <div class="value" data-precision="0" v-num-to="datas.total"></div>
          <div class="name">总条数</div>
        </div>
      </div>
      <div style="margin-top: 10px;flex: 1;height: 0;display: flex;flex-direction: column;">
        <fm-title :title-text="'新冠病毒核酸检测情况统计表（' + dateRange[0] + ' 至 ' + dateRange[1] + '）'"></fm-title>
        <div style="flex: 1;height: 0;width: 650px;" ref="table">
          <Table size="small" :loading="loading.load" :columns="columns" :data="datas.cyddList" border show-summary :summary-method="summaryMethod" :max-height="tableHeight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nucleicReportRequest } from '../../../api'
import { XDate } from '@/components/fmui/components/FmDatePicker/lib'

const shortcuts = Object.freeze([
  { 
    text: '今天',
    value () {
      return [new Date(new XDate().format('Y-M-D 00:00:00')), new Date(new XDate().compute('d', 1).format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '昨天',
    value () {
      return [new Date(new XDate().compute('d', -1).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  {
    text: '近三天',
    value () {
      return [new Date(new XDate().compute('d', -3).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '近七天',
    value () {
      return [new Date(new XDate().compute('d', -7).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  },
  { 
    text: '近十四天',
    value () {
      return [new Date(new XDate().compute('d', -14).format('Y-M-D 00:00:00')), new Date(new XDate().format('Y-M-D 00:00:00'))]
    }
  }
])

export default {
  data () {
    return {
      status: {},
      loading: {
        cydd: false,
        load: false
      },
      cyddList: [],
      search: {
        cydd: null,
        cyrq: [new Date(new XDate().format('Y-M-D 00:00:00')), new Date(new XDate().compute('D', 1).format('Y-M-D 00:00:00'))]
      },
      datas: {
        mix: 0,
        single: 0,
        total: 0,
        sysTotal: 0,
        cyddList: []
      },
      tableHeight: 0
    }
  },
  computed: {
    columns () {
      return [
        { key: 'cydd', title: '采样单位', maxWidth: 280, align: 'center', ellipsis: true, tooltip: true },
        { key: 'single', title: '单采管数', align: 'center' },
        { key: 'mix', title: '混采管数', align: 'center' },
        { key: 'sys', title: '系统采集数', align: 'center' },
        { key: 'total', title: '合计', align: 'center' }
      ]
    },
    dateOptions () {
      return { shortcuts }
    },
    dateRange () {
      if (this.search.cyrq) {
        return [this.$datetime.format(this.search.cyrq[0], 'Y年m月d日'), this.$datetime.format(this.search.cyrq[1], 'Y年m月d日')]
      } else {
        return ['', '']
      }
    },
    singleTotal () {
      return this.datas.cyddList.reduce((a, b) => a + b.single, 0)
    },
    mixTotal () {
      return this.datas.cyddList.reduce((a, b) => a + b.mix, 0)
    },
  },
  methods: {
    async loadCydd () {
      this.loading.cydd = true
      const res = await nucleicReportRequest.cydd()
      this.cyddList = res.filter(v => v).map(v => v.cydd)
      this.loading.cydd = false
    },
    async loadData () {
      if (!this.$authFunsProxy.count && !this.$authFunsProxy.countMyOrg) {
        throw new Error('无权限')
      }
      const parm = {}

      if (!this.search.cyrq) {
        return this.$notice.warning('请选择采样日期'), false
      }

      if (!this.search.cyrq[0]) {
        return this.$notice.warning('请选择起始采样日期'), false
      }

      if (!this.search.cyrq[1]) {
        return this.$notice.warning('请选择截止采样日期'), false
      }

      parm.minCyrq = this.$datetime.format(this.search.cyrq[0], 'Y-M-D')
      parm.maxCyrq = this.$datetime.format(this.search.cyrq[1], 'Y-M-D')

      if (this.search.cydd) {
        parm.cydd = this.search.cydd
      }

      this.loading.load = true
      this.datas = []
      if (this.$authFunsProxy.count) {
        await nucleicReportRequest.counts(parm)
      } else {
        await nucleicReportRequest.countsMyOrg(parm)
      }
      this.loading.load = false
    },
    summaryMethod ({ columns }) {
      const sums = {}
      columns.forEach((column) => {
        if (column.key === 'cydd') {
          sums[column.key] = { key: 'cydd', value: '合计' }
        } else if (column.key === 'sys') {
          sums[column.key] = { key: column.key, value: this.datas.sysTotal }
        } else if (column.key === 'total') {
          sums[column.key] = { key: column.key, value: this.datas.total }
        } else {
          sums[column.key] = {
            key: column.key,
            value: this.datas.cyddList.reduce((a, b) => a + b[column.key], 0)
          }
        }
      })
      return sums
    },
    loadTableHeight () {
      if (this.$refs.table) {
        this.tableHeight = (Array.isArray(this.$refs.table) ? this.$refs.table[0].offsetHeight : this.$refs.table.offsetHeight) - 50
      }
    }
  },
  mounted () {
    this.loadCydd()
    this.loadData()
    this.loadTableHeight()
    this.$nextTick(this.loadTableHeight)
    window.addEventListener('resize', this.loadTableHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.loadTableHeight)
  }
}
</script>
